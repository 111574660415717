import React from "react";
import Layout from "../../components/Layout";
import RightColumn from "../../components/RightColumn";

export default class TechnicalServicesPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo04.jpg" sidebarImageAlt="Technical Services" metaTitle="Technical Services">
        <div id="mainContent">
          <div class="header-one">Technical Services</div>
          <p>
            <strong>WBA Analytical Laboratories</strong>
            <strong>&trade;</strong>&nbsp; staff have a wide range of expertise
            in the areas of laboratory, food safety, processing, and sanitation
            practices.&nbsp; Below are&nbsp;some examples&nbsp;of
            the&nbsp;technical services we provide.&nbsp;&nbsp;
            <a href="/contact-us">Contact us</a> for additional details and
            pricing.
          </p>
          <p class="header-two">Check-Sample Program</p>
          <p>
            WBA Analytical Laboratories&trade; provides an affordable
            check-sample program to help your business verify the competency of
            your laboratory staff.&nbsp; Each program event consists of four
            samples for microbiological evaluation for spoilage organisms (APC,
            Coliforms, E. coli, Staph) for your technicians to test and submit
            results to WBA. Data analysis is performed and a&nbsp;report
            provided with z-scores for each technician for each sample tested.
          </p>
          <p class="header-two">Microbiology Training</p>
          <p>
            Our Springdale, Arkansas, facility has a classroom laboratory where
            classes are routinely held for microbiology training. Courses can be
            customized for your specific needs, and training can be provided
            on-site in your laboratories.
          </p>
          <p class="header-two">Food Safety and Technical Auditing</p>
          <p>
            In addition to training, our Food Safety and Technical Auditors can
            provide you with thorough laboratory and food safety audit programs
            tailored to your operations.
          </p>
          <p class="header-two">Monitoring Programs</p>
          <p>
            Our consulting team can help you design and implement
            environmental-monitoring programs and provide testing and customized
            reports to support your program to help you maximize the value of
            monitoring and make fact-based decisions.
          </p>
          <p class="header-two">Troubleshooting</p>
          <p>
            Our team has years of experience in troubleshooting food-safety
            problems.&nbsp; We can come on-site and provide comprehensive
            evaluations to target problems, provide recommendations, conduct
            sampling, and perform testing and data analysis to help you get your
            organization moving forward.{" "}
          </p>
        </div>

        <RightColumn />
      </Layout>
    );
  }
}
